<template>
  <div>
    <div class="is-fab has-content-centered">
      <button class="button is-success is-rounded" @click="addClick">
        <span class="icon">
          <i :class="iconClass"></i>
        </span>
        <span>{{ title }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FabButton',
  props: {
    title: {
      type: String,
      required: true
    },
    iconClass: {
      type: String,
      required: true
    }
  },
  methods: {
    addClick () {
      this.$emit('addClick', '')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~bulma";

  .is-fab {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    // :hover {
    //   color: bulmaLighten($primary, 2.5%);
    // }
  }
</style>
